import styled from "styled-components";
import { useAppState } from "../AppContext";
import SSP from "../assets/SSP.png";
import { PrimaryButton } from "../styles";

interface BottomCardProps {
  subtitle: string;
  text: string;
  cta: boolean;
  ctaText?: string;
}

const BottomCard = ({ subtitle, text, cta, ctaText }: BottomCardProps) => {
  const { nextPage } = useAppState();
  return (
    <BottomHalf>
      <LittleCard>
        <SspText src={SSP} />
        <SubTitle>{subtitle}</SubTitle>
        <Text>{text}</Text>
      </LittleCard>
      {cta && <CallToAction onClick={nextPage}>{ctaText}</CallToAction>}
    </BottomHalf>
  );
};

export default BottomCard;

const LittleCard = styled.div`
  background: linear-gradient(
    129.44deg,
    rgba(255, 255, 255, 0.1) 0.7%,
    rgba(255, 255, 255, 0) 103.98%
  );
  backdrop-filter: blur(10px);
  position: relative;
  width: 100%;
  height: 70%;
  max-width: 434px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 5px;

  @media only screen and (min-height: 900px) {
    height: 100%;
  }
`;

const SubTitle = styled.h2`
  font-family: "Rajdhani", sans-serif;
  font-size: 22px;
  color: #e9e9e9;
  padding-top: 15px;
  text-align: center;
`;

const BottomHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  padding-top: 50px;
`;

const Text = styled.p`
  color: white;
  text-align: center;
  padding: 5px 20px;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;

  @media only screen and (min-height: 600px) {
    font-size: 19px;
  }
`;

const SspText = styled.img`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 1));
  max-width: 60%;

  @media only screen and (max-width: 350px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 400px) {
    max-width: 55%;
  }
  @media only screen and (min-width: 700px) {
    top: -45px;
  }
`;

const CallToAction = styled(PrimaryButton)`
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 21px;
  color: #ffffff;
  background: #9200b7;
  box-shadow: 0px 0px 15px rgba(255, 0, 255, 0.5);
  border-radius: 3px;
  border: none;
`;
