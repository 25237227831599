import { FC } from "react";

import styled from "styled-components";
import rightArrow from "../assets/right-arrow.svg";
import leftArrow from "../assets/left-arrow.svg";
import { useSpring, animated } from "react-spring";
import { useAppState } from "../AppContext";

const calc = (x: number, y: number) => [
  -(y - window.innerHeight / 2) / 200,
  (x - window.innerWidth / 2) / 200,
  1,
];
const trans = (x: number, y: number, s: number) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const CardContainer: FC = ({ children }) => {
  const { nextPage, prevPage, activeSlide } = useAppState();
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  return (
    <Card
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      //@ts-ignore
      style={{ transform: props.xys.interpolate(trans) }}
    >
      {activeSlide === 0 && (
        <RightButton onClick={nextPage}>
          <img src={rightArrow} />
        </RightButton>
      )}
      {activeSlide === 1 && (
        <LeftButton onClick={prevPage}>
          <img src={leftArrow} />
        </LeftButton>
      )}
      <InnerCard>{children}</InnerCard>
    </Card>
  );
};

export default CardContainer;

const Card = styled(animated.div)`
  background: linear-gradient(
    129.44deg,
    rgba(255, 255, 255, 0.1) 0.7%,
    rgba(255, 255, 255, 0) 103.98%
  );
  backdrop-filter: blur(10px);
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 0 11px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-height: 700px) {
    height: 80%;
  }

  @media only screen and (min-width: 560px) {
    width: 85%;
    height: 90%;
    align-items: flex-start;
    padding: 20px;
    max-width: 700px;
    border-radius: 20px;
  }

  @media only screen and (min-height: 1300px) and (min-width: 1000px) {
    height: 60%;
  }
`;

const RightButton = styled.button`
  position: absolute;
  z-index: 9999999;
  top: 25%;
  transform: translateY(-50%);
  right: 0px;
  width: 10px;
  background: transparent;
  border: none;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    img {
      transform: scale(0.9);
    }
  }
  &:focus {
    outline: none;
    &::before {
      background: rgba(225, 225, 225, 0.18);
      transform: translate(-50%, -50%) scale(3.5);
    }
  }

  img {
    width: 15px;
    opacity: 1;
    animation-name: example;
    animation-duration: 1s;

    @keyframes example {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  @media only screen and (min-width: 760px) {
    right: -50px;
    width: 80px;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 40px;
    }
  }
  @media only screen and (min-width: 1000px) {
    right: -100px;
  }
`;
const LeftButton = styled.button`
  position: absolute;
  z-index: 9999999;
  top: 25%;
  transform: translateY(-50%);
  left: -10px;
  width: 10px;
  background: transparent;
  border: none;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    img {
      transform: scale(0.9);
    }
  }
  &:focus {
    outline: none;
    &::before {
      background: rgba(225, 225, 225, 0.18);
      transform: translate(-50%, -50%) scale(3.5);
    }
  }

  img {
    width: 15px;
    opacity: 1;
    animation-name: example;
    animation-duration: 1s;

    @keyframes example {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  @media only screen and (min-width: 760px) {
    left: -50px;
    width: 80px;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 40px;
    }
  }
  @media only screen and (min-width: 1000px) {
    left: -100px;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and (min-width: 560px) {
    justify-content: space-evenly;
  }
`;
