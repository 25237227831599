import styled from "styled-components";
import CardContainer from "../CardContainer";
import TopCard from "../TopCard";
import BottomCard from "../BottomCard";
import { useSpring, animated } from "react-spring";

import vette from "../../assets/vette.png";

import slidesInfo from "../../slidesInfo";
import { Dispatch, SetStateAction, useState } from "react";

const { tuning } = slidesInfo;
const { section, subtitle, text, cta, ctaText } = tuning;
const windowWidth = window.innerWidth;

const TuningSlide = () => {
  return (
    <Page>
      <CardContainer>
        <TopCard title={section} imgSrc={vette} />
        <BottomCard
          subtitle={subtitle}
          text={text}
          cta={cta}
          ctaText={ctaText}
        />
      </CardContainer>
    </Page>
  );
};

export default TuningSlide;

const Page = styled(animated.main)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 100px 11px 25px 11px;
`;
