import { FC } from "react";
import styled from "styled-components";
import NavBar from "./NavBar";
import FbLogo from "./FbLogo";
import SpinningRhino from "./SpinningRhino";

const Header: FC = () => {
  return (
    <HeaderContainer>
      <SpinningRhino />
      <NavBar />
      <FbLogo />
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  height: 100px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 9999999;
`;
