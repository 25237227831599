import styled, { createGlobalStyle } from "styled-components";
import Background from "../assets/SSP-background.jpg";

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overscroll-behavior-y: contain;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    background-image: url(${Background}), linear-gradient(#000000, #000000);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover; 
}

`;

export default GlobalStyle;

export const PrimaryButton = styled.button`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: all 0.4s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    border-radius: 50%;
    background: rgba(225, 225, 225, 0);
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.35s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  &:active {
    transform: scale(0.9);
  }
  &:focus {
    outline: none;
    &::before {
      background: rgba(225, 225, 225, 0.18);
      transform: translate(-50%, -50%) scale(3.5);
    }
  }
`;
