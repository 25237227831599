const slidesInfo = {
  tuning: {
    section: "TUNING",
    subtitle: "Services include:",
    text:
      "Unlocking computers, VATS removal, adjusting tire size/gear ratios, upgrading exhaust/intake performance, torque management removal, and much more.",
    cta: true,
    ctaText: "CONTACT US",
  },
  contact: {
    section: "CONTACT",
    subtitle: "SSP Tuning & Performance",
    text: "Give us a call at :",
    phoneNumber: "514-895-9596",
    text2: " or chat with us with facebook below.",
    cta: false,
    ctaText: "",
  },
};

export default slidesInfo;
