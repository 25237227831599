import styled from "styled-components";
import CardContainer from "../CardContainer";
import BottomCard from "../BottomCard";
import { useSpring, animated } from "react-spring";

import headLogo from "../../assets/head-logo.svg";

import slidesInfo from "../../slidesInfo";

const { contact } = slidesInfo;
const { section, subtitle, text, text2, phoneNumber, cta } = contact;

const ContactSlide = () => {
  return (
    <Page>
      <CardContainer>
        <TopHalf>
          <MainImg src={headLogo} alt="SSP sticker logo" />
          <TitleCard>{section}</TitleCard>
        </TopHalf>
        <LittleCard>
          <SubTitle>{subtitle}</SubTitle>
          <Text>
            {text} <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            {text2}
          </Text>
        </LittleCard>
      </CardContainer>
    </Page>
  );
};

export default ContactSlide;

const Page = styled(animated.main)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 100px 11px 25px 11px;
`;

const MainImg = styled.img`
  width: 70%;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.5));

  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (min-width: 500px) {
    height: 70%;
  }
`;

export const TitleCard = styled.h3`
  font-family: "Rajdhani", sans-serif;
  font-size: 22.5vw;
  color: #fff;
  text-align: center;
  line-height: 90%;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  z-index: 99999;
  padding-bottom: 20px;
  @media only screen and (min-width: 560px) {
  }
  @media only screen and (min-width: 700px) {
    font-size: 150px;
  }
  @media only screen and (min-height: 900px) and (min-width: 800px) {
    font-size: 180px;
  }
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 50%;
`;

const LittleCard = styled.div`
  background: linear-gradient(
    129.44deg,
    rgba(255, 255, 255, 0.1) 0.7%,
    rgba(255, 255, 255, 0) 103.98%
  );
  backdrop-filter: blur(10px);
  position: relative;
  width: 100%;
  height: 40%;
  max-width: 434px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 5px;

  @media only screen and (min-height: 900px) {
    height: 90%;
  }
  @media only screen and (min-width: 900px) {
    height: 30%;
  }
`;

const SubTitle = styled.h4`
  font-family: "Rajdhani", sans-serif;
  font-size: 28px;
  color: #f0f0f0;
  padding-top: 15px;
  text-align: center;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
`;

const Text = styled.p`
  color: white;
  text-align: center;
  padding: 5px 20px;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);

  a {
    color: #ffffff;
  }

  @media only screen and (min-height: 600px) {
    font-size: 19px;
  }
`;
