import { FC, useState } from "react";
import BackgroundLine from "../components/BackgroundLine";
import Carousel from "../components/Carousel";
import Header from "../components/header";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useAppState } from "../AppContext";

const Home: FC = () => {
  const { activeSlide } = useAppState();
  return (
    <>
      <Header />
      <BackgroundLine />
      <Carousel />
      {activeSlide === 1 && (
        <MessengerCustomerChat
          pageId="100717355391264"
          appId="808548349730205"
        />
      )}
    </>
  );
};

export default Home;
