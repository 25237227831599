import { FC } from "react";
import styled from "styled-components";
import { useAppState } from "../../AppContext";

interface NavBarProps {}

const NavBar: FC<NavBarProps> = () => {
  const { activeSlide, nextPage, prevPage } = useAppState();
  return (
    <Nav>
      <NavButton on={activeSlide === 0} onClick={prevPage}>
        TUNING
      </NavButton>
      <NavButton on={activeSlide === 1} onClick={nextPage}>
        CONTACT
      </NavButton>
    </Nav>
  );
};

export default NavBar;

const Nav = styled.nav`
  max-width: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 440px) {
    justify-content: space-evenly;
  }
`;

const NavButton = styled.button<{ on: boolean }>`
  font-family: "Rajdhani", sans-serif;
  cursor: pointer;
  padding-bottom: 8px;
  background: transparent;
  border: none;
  color: ${(props) => (props.on ? "#ffffff" : "#c5c5c5")};
  border-bottom: ${(props) =>
    props.on ? "3px solid #810081" : "3px solid transparent"};
  font-size: 18px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #ffffff;
  }

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 380px) {
    font-size: 15px;
  }
`;
