import styled from "styled-components";
import CardContainer from "./CardContainer";
import TopCard from "./TopCard";
import BottomCard from "./BottomCard";
import slides from "./slides";
import { useSpring, animated } from "react-spring";

import vette from "../assets/vette.png";

import slidesInfo from "../slidesInfo";
import { Dispatch, SetStateAction, useState } from "react";
import TuningSlide from "./slides/TuningSlide";
import ContactSlide from "./slides/ContactSlide";
import { useAppState } from "../AppContext";

const { tuning } = slidesInfo;
const { section, subtitle, text, cta, ctaText } = tuning;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Carousel = () => {
  const { activeSlide } = useAppState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  return (
    <Container
      style={{
        width: `${slides.length * 100}%`,
        left: activeSlide * -windowDimensions.width,
      }}
    >
      <TuningSlide />
      <ContactSlide />
    </Container>
  );
};

export default Carousel;

const Container = styled(animated.main)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
`;
