import { FC } from "react";
import styled from "styled-components";
import bodyLogo from "../../assets/body-logo.svg";

const SpinningRhino: FC = () => {
  return (
    <Rhino>
      <RhinoImage src={bodyLogo} />
    </Rhino>
  );
};

export default SpinningRhino;

const Rhino = styled.div`
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: rotateY(180deg) scale(0.7);
  }
`;

const RhinoImage = styled.img`
  @media only screen and (max-width: 380px) {
    width: 60px;
  }
`;
