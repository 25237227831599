import styled from "styled-components";

interface TopCardProps {
  title: string;
  imgSrc: string;
}

const TopCard = ({ title, imgSrc }: TopCardProps) => {
  return (
    <TopHalf>
      <ImgAndTitle>
        <TitleCard>{title}</TitleCard>
        <MainImg src={imgSrc} />
      </ImgAndTitle>
    </TopHalf>
  );
};

export default TopCard;

const TitleCard = styled.h1`
  font-family: "Rajdhani", sans-serif;
  font-size: 25.5vw;
  color: #fff;
  text-align: center;
  opacity: 0.15;
  padding-bottom: 50px;

  @media only screen and (min-width: 560px) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 700px) {
    font-size: 150px;
    padding-bottom: 75px;
  }
  @media only screen and (min-height: 900px) and (min-width: 800px) {
    font-size: 200px;
    padding-bottom: 110px;
  }
`;
const MainImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
`;

const TopHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImgAndTitle = styled.div`
  position: relative;
`;
