import { FC, createContext, useContext, useState, useCallback } from "react";
import slides from "./components/slides";

interface StateContextProps {
  activeSlide: number;
  nextPage(): void;
  prevPage(): void;
  setIndex(i: number): void;
}

const StateContext = createContext<StateContextProps | undefined>(undefined);

const AppProvider: FC = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const nextPage = useCallback(() => {
    if (activeSlide < slides.length - 1) {
      setActiveSlide(activeSlide + 1);
    }
  }, [activeSlide, setActiveSlide]);
  const prevPage = useCallback(() => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  }, [activeSlide, setActiveSlide]);

  const setIndex = useCallback(
    (i) => {
      setActiveSlide(i);
    },
    [activeSlide, setActiveSlide]
  );

  return (
    <StateContext.Provider
      value={{ activeSlide, nextPage, prevPage, setIndex }}
    >
      {children}
    </StateContext.Provider>
  );
};

// CUSTOM HOOKS
// One to access state and one to access dispatch
const useAppState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
};

export { AppProvider, useAppState };
