import { FC } from "react";
import { AppProvider } from "./AppContext";
import Home from "./pages/Home";
import GlobalStyle from "./styles";

const App: FC = () => {
  return (
    <>
      <AppProvider>
        <GlobalStyle />
        <Home />
      </AppProvider>
    </>
  );
};

export default App;
