import { FC, useState } from "react";
import styled from "styled-components";

const FbLogo: FC = () => {
  const [fbColor, setFbColor] = useState<string>("#54006A");

  const handleInFb = () => setFbColor("#3b5998");
  const handleOutFb = () => setFbColor("#54006A");

  return (
    <FbButton
      onMouseEnter={handleInFb}
      onMouseLeave={handleOutFb}
      href="https://www.facebook.com/SSPtuningperformance"
      target="_blank"
    >
      <FbSVG
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.5 39C30.2695 39 39 30.2696 39 19.5C39 8.73045 30.2695 0 19.5 0C8.73047 0 0 8.73045 0 19.5C0 30.2696 8.73047 39 19.5 39ZM21.7273 9H25V13.4H21.7273C21.438 13.4 21.1605 13.5159 20.9559 13.7222C20.7513 13.9285 20.6364 14.2083 20.6364 14.5V17.8H25L23.9091 22.2H20.6364V31H16.2727V22.2H13V17.8H16.2727V14.5C16.2727 13.0413 16.8474 11.6424 17.8704 10.6109C18.8933 9.57946 20.2806 9 21.7273 9Z"
          fill={fbColor}
        />
      </FbSVG>
    </FbButton>
  );
};

export default FbLogo;

const FbButton = styled.a`
  cursor: pointer;
`;

const FbSVG = styled.svg`
  transition: all 0.5s ease-in-out;
  @media only screen and (max-width: 380px) {
    width: 30px;
  }
`;
