import * as React from "react";
import styled from "styled-components";
import Line from "../assets/line.svg";

const BackgroundLine = () => {
  return <SVG src={Line} />;
};

export default BackgroundLine;

const SVG = styled.img`
  opacity: 0.35;
  filter: drop-shadow(4px 4px 12px rgba(255, 0, 255, 0.5));
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
